.resizeContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .leftPanel {
    display: flex;
    flex-grow: 1;
    max-width: 564px;
    background-color: white;

    .inputSectionContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px;

      .inputTextArea {
        min-height: 84px;
      }

      .dimensionList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .infoText {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: -4px;
        }

        .dimensionListItem {
          display: flex;
          padding: none;
          gap: 8px;
        }
      }

      .customDimensionContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: -20px;

        .customDimensionInputRow {
          display: flex;
          gap: 8px;

          .customDimensionInput {
            display: flex;
            flex: 1 1 50%;
            align-items: center;
            gap: 8px;
          }

          .deleteButton {
            border: none;
          }
        }

        .addCustomDimension {
          text-align: left;
          padding-left: 8px;
        }
      }
    }
  }

  .resultsContainer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 976px;
    height: 100%;
    flex-grow: 1;
    background-color: #f5f5f5;
    gap: 28px;
    padding: 24px;

    .buttonsRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      width: 100%;
    }

    .image {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 8px;

      .button {
        border: none;
      }
    }

    .emptyState {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}
