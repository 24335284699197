.divider {
  height: 100vh;
  margin: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  :global {
    .ant-typography {
      margin-bottom: 0.5em;
    }
  }
}
